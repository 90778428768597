/*!
 *
 * swipe-elem.js
 *
 */

//var isTouchDevice = ('ontouchstart' in window);

$.SwipeElem = function (_this, _opt) {
	this.$target = $(_this);
	this.opt = $.extend({}, this.settings, _opt);
	this.init();
};

$.SwipeElem.prototype = {
	init: function () {
		this.build();
		this.event();
		this.setIcon();
	},
	build: function () {
		this.$target.css('min-width', this.opt.width);
		this.$target.wrap('<div class="' + this.opt.elemClass + '"><div class="' + this.opt.elemClass + '_Contents"></div></div>');
		this.$elem = this.$target.closest('.' + this.opt.elemClass);
		this.$wrap = this.$target.closest('.' + this.opt.elemClass + '_Contents');
		this.$wrap.prepend('<div class="' + this.opt.elemClass + '_Icon"><div class="' + this.opt.elemClass + '_Icon_Inner">' + this.scrollText() + '</div><div class="' + this.opt.elemClass + '_Icon_Bg"></div></div>');
		this.$icon = this.$wrap.children('.' + this.opt.elemClass + '_Icon');
		this.$icon.children('.' + this.opt.elemClass + '_Icon_Bg').css('min-width', this.opt.width);
		if (this.$target.data('icon') === 'top') {
			this.$icon.addClass(this.opt.elemClass + '_Icon' + '-top');
		};

		if (this.$target.children('caption').length) {
			const cap = this.$target.children('caption').html();
			this.$wrap.before('<div class="' + this.opt.elemClass + '_Caption">' + cap + '</div>');
		};
		if (this.$target.children('figcaption').length) {
			const cap = this.$target.children('figcaption').html();
			this.$wrap.after('<div class="' + this.opt.elemClass + '_Figcaption">' + cap + '</div>');
		};

		this.$wrap.find('img').on('mousedown wrap', function (e) {
			e.preventDefault();
		});
	},
	event: function () {
		var self = this,
			timer = null;

		this.scrolling = false;

		$(window).on('resize', function () {
			self.setIcon();
		});

		this.$wrap.on('scroll', function (e) {
			self.scrolling = true;
			self.$icon.removeClass('-visible');
		});
	},
	setIcon: function () {
		if (this.opt.width > this.$wrap.innerWidth()) {
			this.scrolling = false;
			this.$icon.addClass('-visible');
		} else {
			this.scrolling = true;
			this.$icon.removeClass('-visible');
		};
	},
	scrollText: function () {
		if (util.isTouchDevice) {
			return '左右にスワイプしてご覧ください'
		} else {
			return '左右にスクロールしてご覧ください'
		};
	},
	settings: {
		width: 890,
		elemClass: 'sw-Swipe'
	}
};

$.fn.swipeElem = function (options) {
	var _opt = options ? options : {};
	return this.each(function () {
		new $.SwipeElem(this, _opt);
	});
};