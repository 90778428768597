"use strict";
/*!
 *
 *  sp-expander.ts
 *
 */
var SPExpander = /** @class */ (function () {
    function SPExpander(elem, opt) {
        this.defaultClass = {
            headClass: '.c-Expander_Head',
            bodyClass: '.c-Expander_Body',
            expandClass: '-expanded'
        };
        this.setting = {};
        this.$elem = $(elem);
        this.$item = this.$elem.children();
        this.setting = $.extend({}, this.defaultClass, opt);
        this.$head = this.$item.find(this.setting.headClass);
        this.setEvent();
    }
    SPExpander.prototype.setEvent = function () {
        var _this = this;
        this.$head.on('click', function (e) {
            e.preventDefault();
            if (!$(e.currentTarget).is('.' + _this.setting.expandClass)) {
                $(e.currentTarget).addClass(_this.setting.expandClass);
                $(e.currentTarget).next(_this.setting.bodyClass).stop(false, true).animate({
                    height: 'show'
                }, 500, 'easeOutExpo', function () {
                    $('body').getNiceScroll().resize();
                });
            }
            else {
                $(e.currentTarget).removeClass(_this.setting.expandClass);
                $(e.currentTarget).next(_this.setting.bodyClass).stop(false, true).animate({
                    height: 'hide'
                }, 500, 'easeOutExpo', function () {
                    $('body').getNiceScroll().resize();
                });
            }
            ;
        });
        // this.$item.on('mouseenter', (e) => {
        // 	if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
        // 		if (!util.isTouchDevice) {
        // 			$(e.currentTarget).addClass(this.setting.expandClass);
        // 		};
        // 	};
        // }).on('mouseleave', (e) => {
        // 	if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
        // 		if (!util.isTouchDevice) {
        // 			$(e.currentTarget).removeClass(this.setting.expandClass);
        // 		};
        // 	};
        // });
        // this.$item.on('click', (e) => {
        // 	if (util.viewport === 'tablet' || util.viewport === 'phone') {
        // 		if ($(e.currentTarget).find(this.setting.bodyClass).is(':hidden')) {
        // 			$(e.currentTarget).addClass(this.setting.expandClass);
        // 			$(e.currentTarget).find(this.setting.bodyClass).stop(false, true).animate({
        // 				height: 'show'
        // 			}, 500, 'easeOutExpo', () => {
        // 				$('body').getNiceScroll().resize();
        // 			});
        // 		} else {
        // 			$(e.currentTarget).removeClass(this.setting.expandClass);
        // 			$(e.currentTarget).find(this.setting.bodyClass).stop(false, true).animate({
        // 				height: 'hide'
        // 			}, 500, 'easeOutExpo', () => {
        // 				$('body').getNiceScroll().resize();
        // 			});
        // 		};
        // 	} else {
        // 		if (util.isTouchDevice) {
        // 			if ($(e.currentTarget).is('.' + this.setting.expandClass)) {
        // 				$(e.currentTarget).removeClass(this.setting.expandClass);
        // 			} else {
        // 				$(e.currentTarget).addClass(this.setting.expandClass);
        // 			};
        // 		};
        // 	};
        // });
    };
    return SPExpander;
}());
$.fn.spExpander = function (option) {
    var opt = option ? option : {};
    return this.each(function (i, elem) {
        new SPExpander(elem, opt);
    });
};
