"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.menu = exports.search = exports.global = void 0;
var global = /** @class */ (function () {
    function global() {
        var _this = this;
        this.$header = $('#Header');
        this.$globalItem = $('.st-Global_Item').filter(function (i, elem) {
            return Boolean($(elem).children('.st-Global_Inner').length);
        });
        this.$global2Item = $('.st-Global2_Item').filter(function (i, elem) {
            return Boolean($(elem).children('.st-Global2_Inner').length);
        });
        this.$globalSwitch = $('.st-Global_Switch');
        this.$global2Switch = $('.st-Global2_Switch');
        if (!util.isTouchDevice) {
            this.$globalItem.on('mouseenter', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    _this.on(e.currentTarget, false);
                }
                ;
            }).on('mouseleave', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    _this.off(e.currentTarget, false);
                }
                ;
            });
            this.$global2Item.on('mouseenter', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    _this.on(e.currentTarget, true);
                }
                ;
            }).on('mouseleave', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    _this.off(e.currentTarget, true);
                }
                ;
            });
        }
        else {
            this.$globalItem.on('click', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    if (!$(e.currentTarget).is('.-expanded')) {
                        e.preventDefault();
                        _this.on(e.currentTarget, false);
                    }
                    ;
                }
                ;
            });
            this.$global2Item.on('click', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    if (!$(e.currentTarget).is('.-expanded')) {
                        e.preventDefault();
                        _this.on(e.currentTarget, true);
                    }
                    ;
                }
                ;
            });
            $(document).on('click', function (e) {
                if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
                    var $current = _this.$globalItem.filter(function (i, elem) {
                        return $(elem).is('.-expanded');
                    });
                    if ($current.length && !$(e.target).closest($current).length) {
                        _this.off($current[0], false);
                    }
                    ;
                    var $current2 = _this.$global2Item.filter(function (i, elem) {
                        return $(elem).is('.-expanded');
                    });
                    if ($current2.length && !$(e.target).closest($current2).length) {
                        _this.off($current2[0], true);
                    }
                    ;
                }
                ;
            });
        }
        ;
        this.$globalSwitch.on('click', function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).closest('.st-Global_Item');
            if ($item.is('.-expanded')) {
                $item.removeClass('-expanded');
                $item.children('.st-Global_Inner').stop(false, true).animate({
                    height: 'hide'
                }, 700, 'easeOutQuart');
            }
            else {
                $item.addClass('-expanded');
                $item.children('.st-Global_Inner').stop(false, true).animate({
                    height: 'show'
                }, 700, 'easeOutQuart');
            }
            ;
        });
        this.$global2Switch.on('click', function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).closest('.st-Global2_Item');
            if ($item.is('.-expanded')) {
                $item.removeClass('-expanded');
                $item.children('.st-Global2_Inner').stop(false, true).animate({
                    height: 'hide'
                }, 700, 'easeOutQuart');
            }
            else {
                $item.addClass('-expanded');
                $item.children('.st-Global2_Inner').stop(false, true).animate({
                    height: 'show'
                }, 700, 'easeOutQuart');
            }
            ;
        });
    }
    global.prototype.on = function (elem, isGlobal2) {
        $(elem).addClass('-expanded');
        if (isGlobal2) {
            $(elem).closest('.st-Global_Item').addClass('-expansion');
        }
        else {
            this.$header.addClass('-expanded');
        }
        ;
    };
    global.prototype.off = function (elem, isGlobal2) {
        $(elem).removeClass('-expanded');
        if (isGlobal2) {
            $(elem).closest('.st-Global_Item').removeClass('-expansion');
        }
        else {
            this.$header.removeClass('-expanded');
        }
        ;
    };
    return global;
}());
exports.global = global;
var search = /** @class */ (function () {
    function search() {
        var _this = this;
        this.$header = $('#Header');
        this.$menu = $('#MenuSearch');
        this.$search = $('#Search');
        this.$btn = $('#MenuSearchBtn');
        this.$body = $('#SearchPopularBody');
        this.isHidden = true;
        this.$keywords = this.$search.find('.st-Search_Keywords input');
        this.$close = this.$search.find('.st-Search_Close');
        this.setItem();
        this.$btn.on('click', function (e) {
            e.preventDefault();
            if (_this.isHidden) {
                $(window).trigger('hiddenHero');
                _this.open();
            }
            else {
                _this.close(true);
            }
            ;
        });
        $(document).on('click', function (e) {
            if (!_this.isHidden && !$(e.target).closest(_this.$menu).length && !$(e.target).closest(_this.$search).length) {
                _this.close(true);
            }
            ;
        });
        this.$close.on('click', function (e) {
            e.preventDefault();
            _this.close(true);
        });
        $(window).on('hiddenHero', function () {
            _this.close(false);
        });
    }
    search.prototype.open = function () {
        this.isHidden = false;
        this.$header.addClass('-hidden');
        this.$search.addClass('-expanded');
    };
    search.prototype.close = function (showHeader) {
        this.isHidden = true;
        if (showHeader) {
            this.$header.removeClass('-hidden');
        }
        ;
        this.$search.removeClass('-expanded');
    };
    search.prototype.setItem = function () {
        var _this = this;
        // let res = await axios.get('/assets/js/keywords.json');
        // let keyword = res.data;
        var keyword = searchPopulars;
        keyword.forEach(function (val) {
            _this.$body.append('<button class="st-Search_Popular_Btn">' + val + '</button>');
        });
        this.$popular = this.$search.find('.st-Search_Popular_Btn');
        this.$popular.on('click', function (e) {
            _this.setPopular($(e.target).text());
        });
    };
    search.prototype.setPopular = function (val) {
        var keywords = this.$keywords.val().toString().split(/[ 　]/);
        keywords.push(val);
        keywords = keywords.filter(function (x, i, self) {
            return self.indexOf(x) === i;
        });
        this.$keywords.val(keywords.join(' '));
    };
    return search;
}());
exports.search = search;
var menu = /** @class */ (function () {
    function menu() {
        var _this = this;
        this.$menu = $('#Menu');
        this.$menuBtn = $('#MenuBtn');
        this.$menuBody = $('#MenuBody');
        this.$menuBtn.on('click', function (e) {
            e.preventDefault();
            if (_this.$menu.is('.-expanded')) {
                _this.close();
            }
            else {
                _this.open();
            }
            ;
        });
        $(document).on('click', function (e) {
            if (_this.$menu.is('.-expanded') && !$(e.target).closest(_this.$menuBody).length && !$(e.target).closest(_this.$menuBtn).length) {
                _this.close();
            }
            ;
        });
    }
    menu.prototype.open = function () {
        this.$menu.addClass('-expanded');
        this.$menuBody.stop(false, true).animate({
            height: 'show'
        }, 700, 'easeOutQuart');
    };
    menu.prototype.close = function () {
        this.$menu.removeClass('-expanded');
        this.$menuBody.stop(false, true).animate({
            height: 'hide'
        }, 700, 'easeOutQuart');
    };
    return menu;
}());
exports.menu = menu;
