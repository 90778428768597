"use strict";
/*!
 *
 *  top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.topScroll = exports.topPickup = exports.topSlider = exports.topDep = void 0;
var topDep = /** @class */ (function () {
    function topDep() {
        this.$dep = $('#TopDep');
        this.$item = this.$dep.children('.tp-Dep_Item');
        this.$item.on('click', function (e) {
            if (!$(e.target).closest('.c-Btn').length) {
                e.preventDefault();
                $(e.currentTarget).toggleClass('-expanded');
                $(e.currentTarget).find('.tp-Dep_Body').stop(false, true).animate({
                    height: 'toggle'
                }, 500, 'easeOutExpo', function () {
                    $('body').getNiceScroll().resize();
                });
            }
        });
    }
    return topDep;
}());
exports.topDep = topDep;
// export class topDep {
// 	$dep: JQuery = $('#TopDep');
// 	$item: JQuery = this.$dep.children('.tp-Dep_Item');
// 	constructor() {
// 		this.$item.on('mouseenter', (e) => {
// 			if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
// 				if (!util.isTouchDevice) {
// 					$(e.currentTarget).addClass('-expanded');
// 				};
// 			};
// 		}).on('mouseleave', (e) => {
// 			if (util.viewport !== 'tablet' && util.viewport !== 'phone') {
// 				if (!util.isTouchDevice) {
// 					$(e.currentTarget).removeClass('-expanded');
// 				};
// 			};
// 		});
// 		this.$item.on('click', (e) => {
// 			if (util.viewport === 'tablet' || util.viewport === 'phone') {
// 				if ($(e.currentTarget).find('.tp-Dep_Body').is(':hidden')) {
// 					$(e.currentTarget).addClass('-expanded');
// 					$(e.currentTarget).find('.tp-Dep_Body').stop(false, true).animate({
// 						height: 'show'
// 					}, 500, 'easeOutExpo', () => {
// 						$('body').getNiceScroll().resize();
// 					});
// 				} else {
// 					$(e.currentTarget).removeClass('-expanded');
// 					$(e.currentTarget).find('.tp-Dep_Body').stop(false, true).animate({
// 						height: 'hide'
// 					}, 500, 'easeOutExpo', () => {
// 						$('body').getNiceScroll().resize();
// 					});
// 				};
// 			} else {
// 				if (util.isTouchDevice) {
// 					if ($(e.currentTarget).is('.-expanded')) {
// 						$(e.currentTarget).removeClass('-expanded');
// 					} else {
// 						$(e.currentTarget).addClass('-expanded');
// 					};
// 				};
// 			};
// 		});
// 	}
// }
function topSlider() {
    if ($('#SliderMain').length) {
        setTimeout(function () {
            $('#SliderMain').on('init reInit', function () {
                $('body').getNiceScroll().resize();
            });
            $('#SliderMain').slick({
                slidesToShow: 2,
                arrows: true,
                dots: true,
                autoplay: true,
                autoplaySpeed: 3000,
                appendArrows: $('#SliderArrows'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }, 1000);
    }
    ;
}
exports.topSlider = topSlider;
;
function topPickup() {
    if ($('#PickupMain').length) {
        setTimeout(function () {
            $('#PickupMain').on('init reInit', function () {
                $('body').getNiceScroll().resize();
            });
            $('#PickupMain').slick({
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToShow: 3,
                slidesToScroll: 1,
                appendArrows: $('#PickupArrows'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }, 1000);
    }
    ;
}
exports.topPickup = topPickup;
var topScroll = /** @class */ (function () {
    function topScroll() {
        var _this = this;
        this.$target = $('.st-Main').children();
        this.points = [];
        if ($('#TopHero').length) {
            this.points = this.getPoints();
            $(window).on('resize', function () {
                _this.points = _this.getPoints();
            });
            $(window).on('setPoint', function () {
                _this.points = _this.getPoints();
            });
            $(window).on('scroll', function () {
                var _top = $(window).scrollTop() + (util.wh / 2);
                _this.points.forEach(function (val) {
                    if (!$(val.elem).is('.-scrolled') && _top > val.top) {
                        $(val.elem).addClass('-scrolled');
                    }
                    ;
                });
            });
        }
        ;
    }
    topScroll.prototype.getPoints = function () {
        var points = [];
        this.$target.each(function (i, elem) {
            points.push({
                elem: $(elem),
                top: $(elem).offset().top
            });
        });
        return points;
    };
    return topScroll;
}());
exports.topScroll = topScroll;
