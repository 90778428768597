"use strict";
/*!
 *
 *  hero.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$hero = $('#Hero');
        this.$body = $('#HeroBody');
        this.$btn = $('#HeroBtn');
        this.$header = $('#Header');
        this.$btn.on('click', function (e) {
            e.preventDefault();
            if (_this.$hero.is('.-expanded')) {
                _this.$header.removeClass('-hidden');
                _this.$hero.removeClass('-expanded');
                if (util.viewport === 'tablet' || util.viewport === 'phone') {
                    _this.$body.stop(false, true).animate({
                        height: 'hide'
                    }, 500, 'easeOutQuart', function () {
                        $('body').getNiceScroll().resize();
                    });
                }
                else {
                    _this.$body.stop(false, true).fadeOut(300);
                }
                ;
            }
            else {
                var delay = 500;
                if (util.viewport === 'tablet' || util.viewport === 'phone') {
                    delay = 0;
                }
                ;
                $(window).trigger('hiddenHero');
                _this.$header.addClass('-hidden');
                _this.$hero.addClass('-expanded');
                _this.$body.stop(false, true).delay(delay).animate({
                    height: 'show'
                }, 500, 'easeOutQuart', function () {
                    $('body').getNiceScroll().resize();
                });
            }
            ;
        });
        $(window).on('hiddenHero', function () {
            _this.$hero.removeClass('-expanded');
            _this.$body.stop(false, true).fadeOut(300);
        });
        var $video = $('.hero-Video');
        if ($video.length) {
            if (util.viewport !== 'phone') {
                $('#heroVideo').attr('src', $('#heroVideo').data('pc'));
            }
            else {
                $('#heroVideo').attr('src', $('#heroVideo').data('sp'));
            }
            ;
            $('#Hero').addClass('-init');
        }
        ;
    }
    return default_1;
}());
exports.default = default_1;
