"use strict";
/*!
 *
 *  tab.js
 *
 */
var tabContents = /** @class */ (function () {
    function tabContents(elem, opt) {
        var _this = this;
        this.def = {
            navClass: '.c-Tab_Nav',
            cntClass: '.c-Tab_Contents'
        };
        this.hasHashCurrent = false;
        this.setting = $.extend({}, this.def, opt);
        this.$nav = $(elem).find(this.setting.navClass);
        this.hash = util.hash;
        if (this.hash.search('--') !== -1) {
            this.hash = this.hash.split('--')[0];
        }
        ;
        this.$a = this.$nav.find('a').filter(function (i, elem) {
            return $(elem).is('[href^="#"]');
        });
        this.$contents = $(elem).find(this.setting.cntClass);
        this.$a.each(function (i, item) {
            var _href = $(item).attr('href');
            if (_href === _this.hash) {
                _this.hasHashCurrent = true;
            }
            ;
        });
        this.setDefault();
        this.setEvent();
        $('body').getNiceScroll().resize();
    }
    tabContents.prototype.setDefault = function () {
        var _this = this;
        var $link;
        var $contents;
        this.$a.each(function (i, elem) {
            var _href = $(elem).attr('href');
            if (_this.hasHashCurrent) {
                if (_href === _this.hash) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            else {
                if (i === 0) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            ;
        });
        this.$a.removeClass('-current');
        this.$contents.children().removeClass('-current');
        $link.addClass('-current');
        $contents.addClass('-current');
        if (this.hasHashCurrent) {
            $(window).on('load', function () {
                history.replaceState(null, null, util.hash);
            });
        }
        ;
    };
    tabContents.prototype.setEvent = function () {
        var _this = this;
        this.$a.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(e.currentTarget).is('.-current'))
                return false;
            var _href = $(e.currentTarget).attr('href');
            _this.$nav.find('.-current').removeClass('-current');
            _this.$contents.find('.-current').removeClass('-current');
            $(e.currentTarget).addClass('-current');
            $(_href).addClass('-current');
            $('body').getNiceScroll().resize();
        });
    };
    return tabContents;
}());
;
$.fn.tab = function (options) {
    var opt = options ? options : {};
    if (this.length > 0) {
        return this.each(function () {
            new tabContents(this, opt);
        });
    }
    ;
};
