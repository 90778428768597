"use strict";
/*!
 *
 *  hasher.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function () {
    var hash = util.hash;
    if (util.hash.search('--') !== -1) {
        hash = '#' + util.hash.split('--').slice(-1)[0];
    }
    ;
    if (hash.length > 0 && hash.search('/') === -1) {
        location.hash = util.hash + '_';
        if ($(hash).length) {
            $(window).on('load', function () {
                var offset = $(hash).offset();
                var top = offset !== undefined ? offset.top : 0;
                if ($('body').getNiceScroll(0).length > 0) {
                    $('body').getNiceScroll(0).doScrollTop(top - util.scrollGap);
                }
                else {
                    $(util.scroller).animate({
                        scrollTop: top - util.scrollGap
                    }, 0);
                }
                ;
                history.replaceState(null, '', util.hash);
            });
        }
        else {
            history.replaceState(null, '', util.hash);
        }
        ;
    }
    ;
});
