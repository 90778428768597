"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ScrollAnimation = /** @class */ (function () {
    function ScrollAnimation(elems) {
        this.wy = $(window).scrollTop();
        this.wh = $(window).height();
        var self = this;
        var elem = Array.prototype.slice.call(elems, 0);
        elem.forEach(function (elem) {
            self.init(elem);
        });
    }
    ScrollAnimation.prototype.init = function (elem) {
        var self = this;
        self.wy = $(window).scrollTop();
        self.wh = $(window).height();
        elem.targetPosY = $(elem).offset().top;
        window.addEventListener('load', scrollEvent);
        window.addEventListener('resize', function () {
            self.wy = $(window).scrollTop();
            self.wh = $(window).height();
        });
        window.addEventListener('scroll', function () {
            self.wy = $(window).scrollTop();
            self.wh = $(window).height();
            scrollEvent();
        });
        setTimeout(function () {
            scrollEvent();
        }, 100);
        function scrollEvent() {
            elem.targetPosY = $(elem).offset().top;
            if (self.wy + self.wh * 0.8 > elem.targetPosY) {
                window.removeEventListener('scroll', scrollEvent);
                elem.classList.add("-run");
            }
        }
    };
    return ScrollAnimation;
}());
exports.default = ScrollAnimation;
