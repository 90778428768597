"use strict";
/*!
 *
 *  modal.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$header = $('#Header');
        this.content = [];
        this.isShow = false;
        $('.sw-Modal').each(function (i, elem) {
            $(elem).data('modalIndex', i);
            _this.content.push({
                src: $(elem).attr('href'),
                opts: {
                    animationDuration: 700,
                    transitionDuration: 700,
                    arrows: false,
                    infobar: false,
                    smallBtn: false,
                    toolbar: true,
                    buttons: ['close'],
                    idleTime: 999999,
                    wheel: false,
                    touch: false,
                    beforeShow: function () {
                        _this.isShow = true;
                        _this.$header.addClass('-hidden');
                    },
                    beforeClose: function () {
                        _this.$header.removeClass('-hidden');
                        $('.sw-Modal').removeClass('-show');
                    },
                    afterClose: function () {
                        _this.isShow = false;
                    },
                    afterShow: function (instance, current) {
                        instance.Guestures.$stage.removeClass('-hidden');
                    }
                }
            });
        });
        $(window).on('hiddenHero', function () {
            if (_this.isShow) {
                $.fancybox.close();
            }
            ;
        });
        $('.sw-Modal').on('click', function (e) {
            e.preventDefault();
            var i = $(e.currentTarget).data('modalIndex');
            if (_this.isShow) {
                if ($(e.currentTarget).is('.-show')) {
                    $(e.currentTarget).removeClass('-show');
                    $.fancybox.close();
                }
                else {
                    $('.sw-Modal.-show').removeClass('-show');
                    $(e.currentTarget).addClass('-show');
                    var instance_1 = $.fancybox.getInstance();
                    instance_1.Guestures.$stage.addClass('-hidden');
                    setTimeout(function () {
                        instance_1.jumpTo(i, 0);
                    }, 300);
                }
                ;
            }
            else {
                $(window).trigger('hiddenHero');
                $.fancybox.open(_this.content, {}, i);
                $(e.currentTarget).addClass('-show');
            }
            ;
        });
    }
    return default_1;
}());
exports.default = default_1;
